/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

import { createApp } from 'vue'
import App from './App.vue'
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import Toaster from '@meforma/vue-toaster'

// Global event bus
import mitt from 'mitt'
const emitter = mitt()

// Amplify imports
import Amplify from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    identityPoolRegion: 'us-west-2',
    userPoolId: 'us-west-2_GJKqhZqah',
    userPoolWebClientId: '61467higaemf6rlmjnp1qn0ds8',
    mandatorySignIn: false,
    authenticationFlowType: 'CUSTOM_AUTH',
  }
})

const app = createApp(App)
app.use(VuesticPlugin,{
  components: {
    VaChip: {
      outline: true,
      rounded: false,
      size: 'large',
      color: '#000'
    },
    VaCard:{
      stripe: false,
      stripeColor:"black",
      square: false
    },
    VaButton:{
      color:"#08c18a"
    },
    VaButtoGroup:{
      color:"#08c18a"
    },
    VaProgressCircle:{
      color:'#08c18a'
    },
    VaButtonDropdown:{
      color:'#08c18a'
    }
  },
}).use(Toaster)

/* ===================================================
                      CONFIGURATION
    You must add your own values here! See the tutorial
    in the GitHub repo for more information. @jbesw
   =================================================== */


// ** Websocket connection **
app.config.globalProperties.$poolId = 'us-west-2:c52a11ab-4f20-40ec-920d-c102e2746c74' // 'YourCognitoIdentityPoolId'
//  IoTendpoint: Retrieve this with the CLI command: aws iot describe-endpoint --endpoint-type iot:Data-ATS --region us-west-2
app.config.globalProperties.$host = 'anput1xffmgcz-ats.iot.us-west-2.amazonaws.com' // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'

app.config.globalProperties.$region = 'us-west-2'

// **APIGW OrderManager Service**
app.config.globalProperties.$orderManagerEndpoint='https://h3mc2i0kq3.execute-api.us-west-2.amazonaws.com/Prod/'

// **APIGW  Validator Service **
app.config.globalProperties.$APIGWEndpointValidatorService = 'https://ubhlu82ahd.execute-api.us-west-2.amazonaws.com/Prod/'

// **APIGW config Service **
app.config.globalProperties.$APIGWEndpointConfigService = 'https://k2d3m0h01b.execute-api.us-west-2.amazonaws.com/Prod'

// ** readonly config store endpoint **
app.config.globalProperties.$ConfigEndpoint = 'https://k2d3m0h01b.execute-api.us-west-2.amazonaws.com/Prod/config'

// ** readonly config store endpoint **
//app.config.globalProperties.$journeyServiceURL = 'https://d19wrgup6knp98.cloudfront.net/'
app.config.globalProperties.$journeyServiceURL = 'https://serverlessland.com/sls-order-journey?orderId='


app.config.globalProperties.emitter = emitter
app.config.globalProperties.$adminApp = false
app.mount('#app')
